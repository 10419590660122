"use strict";
class GpuRenderTarget {
  constructor() {
    this.contexts = [];
    this.msaaTextures = [];
    this.msaaSamples = 1;
  }
}

export { GpuRenderTarget };

